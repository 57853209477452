<script lang="ts" setup>
import { useSchemaOrg, defineWebPage } from '@unhead/schema-org/vue';
import { useSdk } from '~/sdk';

const { locale } = useI18n();
const { data, error } = await useAsyncData('homepage-' + locale.value, async () => {
  return useSdk().cms.getContent({
    entry: {
      section: 'homepage',
      slug: 'homepage',
    },
  });
});

useSeoMeta({
  title: data.value?.seo?.title,
  description: data.value?.seo?.description,
  ogTitle: data.value?.seo?.title,
  ogDescription: data.value?.seo?.description,
});

useSchemaOrg([
  defineWebPage({
    title: data.value?.seo?.title,
    description: data.value?.seo?.description,
    image: data.value?.seo?.image,
  }),
]);

const components = computed(() => {
  return data.value?.components ?? [];
});

const templates: any = {
  'carousel-block': defineAsyncComponent(() => import('../components/BlockCarousel.vue')),
  'categories-block': defineAsyncComponent(() => import('../components/BlockCategories.vue')),
  'text-block': defineAsyncComponent(() => import('../components/BlockText.vue')),
  'products-block': defineAsyncComponent(() => import('../components/BlockProducts.vue')),
  'features-block': defineAsyncComponent(() => import('../components/BlockFeatures.vue')),
};
</script>

<template>
  <div
    class="space-y-16"
    :class="components && components.length > 0 && components[0].__typename == 'carousel-block' ? '' : 'py-16'"
  >
    <div v-for="component in components" :key="component.id" :is="component.__typename">
      <component :is="templates[component.__typename]" v-bind="component"></component>
    </div>
  </div>
</template>
